import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import PageTitle from "../../components/page-title"
import Constrain from "../../components/constrain"
import Paragraph from "../../components/paragraph"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import VideoPlayer from "../../components/video-player"

const Katalog29 = () => {
  const data = useStaticQuery(graphql`
    query {
      poster: file(relativePath: { eq: "videos/poster-pindar.jpg" }) {
        ...largeImage
      }
    }
  `)
  return (
    <Layout backdrop="aussen">
      <Seo title="Wer war Pindar?" />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Wer war Pindar?",
                link: "/k/29",
              },
            ]}
          />
          <PageTitle>Wer war Pindar?</PageTitle>
          <Constrain align="left">
            <Paragraph>Interview mit Wilfried Stroh</Paragraph>
          </Constrain>
          <VideoPlayer src="pindar-komplett" poster={data.poster} />
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Katalog29
